import { Component, OnInit, Input } from '@angular/core';
import { Device } from 'app/model/device';
import { Router } from '@angular/router';

@Component({
    selector: 'app-device-summary',
    templateUrl: './device-summary.component.html',
    styleUrls: ['./device-summary.component.css']
})
export class DeviceSummaryComponent implements OnInit {
    @Input() device: any;
    @Input() deviceLastUpdated: Date;
    isSmartMilesConnectedCar: boolean;
    location = '';

    constructor(private router: Router) { }

    ngOnInit(): void {
        this.setIsSmartMilesConnectedCar();
        if (this.deviceLastUpdated) {
            this.device.device.lastUpdated = this.deviceLastUpdated;
        }
    }

    getUrlForStatusImage(): string {
        const previousDayIndex = 1;
        if (this.device?.device && this.device.ratedMileage.length > 1) {
            if (!this.device.ratedMileageDataUnavailable && (this.device.ratedMileage[previousDayIndex].heartbeatIndicator || this.device.ratedMileage[previousDayIndex + 1].heartbeatIndicator)) {
                if (this.device.device.status.toLowerCase().includes('installed') || this.device.device.status.toLowerCase().includes('active') || this.device.device.status === 'Data status: Sharing') {
                    this.device.device.status = 'Device: Active';
                    return this.getStatusImage('active');
                }
            }
            if (this.device.device.status.toLowerCase().includes('shipped') ||
                this.device.device.status.toLowerCase().includes('not') ||
                this.device.device.status.toLowerCase().includes('returned')) {
                return this.getStatusImage('not active');
            } else if (this.device.device.status.toLowerCase().includes('active')) {
                this.device.device.status = 'installed';
            } else {
                return this.getStatusImage('other');
            }
        }
    }

    setIsSmartMilesConnectedCar(): void {
        this.isSmartMilesConnectedCar = false;
        if (this.device.scoringModel === 'SM1' || this.device.scoringModel === 'SM2') {
            if (this.device.vendorIdCode === 'FORD' || this.device.vendorIdCode === 'TOYOTA') {
                this.isSmartMilesConnectedCar = true;
            }
        }
    }

    setLastUpdate(): Date {
        this.location = this.router.url;
        if (this.device.device.status === 'Data status: Not Sharing') {
            if (!this.location.toLowerCase().includes('monthlysummary')) {
                return undefined;
            }
        }
        return this.device.device.lastUpdated;
    }

    getStatusImage(status: string): string {
        if (status === 'active') {
            if (this.isSmartMilesConnectedCar) {
                this.device.device.status = 'Data status: Sharing';
                return '/assets/image/CC_Active.png';
            }
            return '/assets/image/svg/device-color-center-success.svg';
        } else if (status === 'not active') {
            if (this.isSmartMilesConnectedCar) {
                this.device.device.status = 'Data status: Not Sharing';
                return '/assets/image/CC_Inactive.png';
            }
            return '/assets/image/svg/device-color-none.svg';
        } else {
            if (this.isSmartMilesConnectedCar) {
                this.device.device.status = 'Data status: Not Sharing';
                return '/assets/image/CC_Inactive.png';
            }
            return '/assets/image/svg/device-color-center-fail.svg';
        }
    }
}
