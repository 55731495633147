import {ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Vehicle } from 'app/model/vehicle';
import * as moment from 'moment';
import { Moment } from 'moment';
import { TimeFrameUnit } from 'app/enum/timeframe';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { TimeframeService } from 'app/service/timeframe.service';
import { UserService } from 'app/service/user.service';
import { LoggerService } from 'app/service/logger.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-smartmiles2-device-summary',
  templateUrl: './smartmiles2-device-summary.component.html',
  styleUrls: ['./smartmiles2-device-summary.component.css']
})
export class Smartmiles2DeviceSummaryComponent implements OnInit {
  MONTH = TimeFrameUnit.MONTH;
  WEEK = TimeFrameUnit.WEEK;
  DAY = TimeFrameUnit.DAY;
  TERM = TimeFrameUnit.TERM;

  isSmartMiles2 = true;
  @Input() vehicle: Vehicle;
  @Input() device: any;
  @Input() deviceLastUpdated: Date;
  isSmartMilesConnectedCar: boolean;
  location = '';
  timeFormat = 'LLL d';
  timeFormatEnd = 'LLL d, yyyy';
  timeFormatStartMobile = 'LLLL d';
  timeFormatDayStartMobile = 'EEEE MMM d, yyyy';
  timeFormatEndMobile = 'LLL d, yyyy';
  timeFrameStart: Moment;
  timeFrameEnd: Moment;
  timeframe = this.MONTH;
  canDecrement = false;
  canIncrement = true;
  partialMonth = false;
  displayTimeBar = true;
  tripsAndMileageLink = false;
  private routerSubscription: Subscription;

  // eslint-disable-next-line max-params
  constructor(
      private route: ActivatedRoute,
      public router: Router,
      public user: UserService,
      public timeframeService: TimeframeService,
      public changeDetector: ChangeDetectorRef,
      private logger: LoggerService) { }

  ngOnInit(): void {
    this.setIsSmartMilesConnectedCar();
    if (this.deviceLastUpdated) {
        this.vehicle.device.lastUpdated = this.deviceLastUpdated;
    }
    this.setupSubscriptions();
}

ngAfterViewInit(): void {
    this.changeDetector.detectChanges();

    this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(this.setupSubscriptions);
}

setupSubscriptions = (): void => {
    const startDateString = this.route.snapshot.paramMap.get('amount');
    this.tripsAndMileageLink = false;
    if (!moment(startDateString).isValid()) {
        this.logger.error(`Invalid start date "${startDateString}"`);
        this.router.navigateByUrl(`/404Error`, { replaceUrl: true });
        return;
    }
    const timeUnit = this.route.snapshot.paramMap.get('unit');
    if (this.shouldRedirectToCurrentDay(timeUnit, startDateString)) {
        this.redirectToCurrentDay(timeUnit);
    }
    this.timeFrameStart = moment(startDateString);
    if (timeUnit === TimeFrameUnit.TERM) {
        this.timeFrameStart = moment(this.user.getTermStart());
        this.timeFrameEnd = moment(this.user.getTermEnd());
        this.timeframe = TimeFrameUnit.TERM;
        this.setTimeFormatMonth();
        this.setTimeNavigationRules();
        return;
    } else if (timeUnit === TimeFrameUnit.WEEK) {
        this.timeFrameStart = moment(startDateString).startOf('week');
        if (this.timeFrameStart.isBefore(this.vehicle.smEnrollDate, 'day')) {
            this.timeFrameStart = moment(this.vehicle.smEnrollDate).startOf('week');
        }
        this.timeFrameEnd = this.timeFrameStart.clone().add(1, 'week').subtract(1, 'day');
        this.timeFormat = TimeFrameUnit.WEEK;
        this.timeframe = TimeFrameUnit.WEEK;
    } else if (timeUnit === TimeFrameUnit.DAY) {
        this.tripsAndMileageLink = true;
        this.timeFrameStart = moment(startDateString).startOf('day');
        if (this.timeFrameStart.isBefore(this.vehicle.smEnrollDate, 'minutes')) {
            this.timeFrameStart = moment(this.vehicle.smEnrollDate).startOf('day');
        }
        this.timeFrameEnd = this.timeFrameStart.clone().endOf('day');
        this.timeFormat = 'EEEE MMM d, yyyy';
        this.timeframe = TimeFrameUnit.DAY;
    } else {
        this.user.setTermStart(new Date(this.vehicle.periodStart.valueOf()));
        this.user.setTermEnd(new Date(this.vehicle.periodEnd.valueOf()));
        const newTf = this.vehicle.getTermLimitsContainingMoment(moment(startDateString));
        this.timeFrameStart = moment.max(newTf.start.clone(), moment(this.vehicle.smEnrollDate));
        this.timeframe = TimeFrameUnit.MONTH;
        this.timeFrameEnd = newTf.end.clone();
    }
    this.setTimeFormatMonth();
    this.setTimeNavigationRules();
};

setLabel(timeStartMoment: Moment, timeEndMoment: Moment): string {
    return `${timeStartMoment.format('MMMM Do')}-${timeEndMoment.format('MMMM Do')}`;
}

getUrlForStatusImage(): string {
    const previousDayIndex = 1;
    if (this.vehicle?.device && this.vehicle.ratedMileage.length > 1) {
        if (!this.vehicle.ratedMileageDataUnavailable && (this.vehicle.ratedMileage[previousDayIndex].heartbeatIndicator || this.vehicle.ratedMileage[previousDayIndex + 1].heartbeatIndicator)) {
            if (this.vehicle.device.status.toLowerCase().includes('installed') || this.vehicle.device.status.toLowerCase().includes('active') || this.vehicle.device.status === 'Sharing') {
                this.vehicle.device.status = 'Sharing';
                return this.getStatusImage('active');
            }
        }
        if (this.vehicle.device.status.toLowerCase().includes('shipped') ||
            this.vehicle.device.status.toLowerCase().includes('not') ||
            this.vehicle.device.status.toLowerCase().includes('returned')) {
            this.vehicle.device.status = 'Not Sharing';
            return this.getStatusImage('not active');
        } else {
            this.vehicle.device.status = 'Not Sharing';
            return this.getStatusImage('other');
        }
    }
}

setIsSmartMilesConnectedCar(): void {
    this.isSmartMilesConnectedCar = false;
    if (this.vehicle.scoringModel === 'SM1' || this.vehicle.scoringModel === 'SM2') {
        if (this.vehicle.vendorIdCode === 'FORD' || this.vehicle.vendorIdCode === 'TOYOTA') {
            this.isSmartMilesConnectedCar = true;
        }
    }
}

setLastUpdate(): Date {
    this.location = this.router.url;
    return this.vehicle.device.lastUpdated;
}

getStatusImage(status: string): string {
    if (status === 'active') {
        if (this.isSmartMilesConnectedCar) {
            this.vehicle.device.status = 'Sharing';
            return '/assets/image/svg/ccConnected_40.svg';
        }
        return '/assets/image/svg/deviceSuccessActive_40.svg';
    } else if (status === 'not active') {
        if (this.isSmartMilesConnectedCar) {
            this.vehicle.device.status = 'Not Sharing';
            return '/assets/image/svg/ccNotConnected_40.svg';
        }
        return '/assets/image/svg/deviceErrorActive_40.svg';
    } else {
        if (this.isSmartMilesConnectedCar) {
            this.vehicle.device.status = 'Not Sharing';
            return '/assets/image/svg/ccNotConnected_40.svg';
        }
        return '/assets/image/svg/deviceErrorActive_40.svg';
    }
}

    getHeaderText(): String {
        this.location = this.router.url;
        if (this.location.toLowerCase().includes('monthlysummary')) {
            return 'Monthly summary';
        } else if (this.location.toLowerCase().includes('weeklysummary')) {
            return 'Trips and mileage ';
        } else if (this.location.toLowerCase().includes('dailysummary')) {
            return 'Daily summary';
        } else if (this.location.toLowerCase().includes('totalsummary')) {
            return 'Total miles driven this term';
        }
    }

    decrementTimeSlice(enterKey: boolean): void {
        if (this.canDecrement) {
            switch (this.route.snapshot.paramMap.get('unit')) {
                case TimeFrameUnit.DAY:
                    this.router.navigate([this.composeUrlForPreviousDay()]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart.clone().subtract(1, 'day').startOf('day'),
                        end: this.timeFrameStart.clone().subtract(1, 'day').endOf('day')
                    });
                    break;

                case TimeFrameUnit.WEEK:
                    this.router.navigate([this.composeUrlForPreviousWeek()]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart.clone().subtract(1, 'week').startOf('week'),
                        end: this.timeFrameStart.clone().subtract(1, 'day')
                    });
                    break;

                case TimeFrameUnit.MONTH: {
                    this.timeFrameStart.subtract(1, 'month');
                    if (this.timeFrameStart.isBefore(this.vehicle.periodStart)) {
                        this.timeFrameStart = moment(this.vehicle.periodStart);
                    }
                    this.timeFrameEnd = this.timeFrameStart.clone().add(1, 'month').subtract(1, 'day').endOf('day');
                    const routeUrl = this.router.url;
                    this.router.navigate([this.composeUrlForPreviousMonth(routeUrl)]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart,
                        end: this.timeFrameEnd
                    });
                } break;
                case TimeFrameUnit.TERM: {
                    const sixMonths = 6;
                    const twelveMonths = 12;
                    let termMonths = sixMonths;
                    termMonths = moment(this.user.getTermEnd()).diff(moment(this.user.getTermStart()), 'months') > sixMonths ? twelveMonths : sixMonths;
                    const timeStart = new Date(this.vehicle.periodStart.valueOf());
                    timeStart.setMonth(timeStart.getMonth() - termMonths);
                    this.timeFrameStart = moment(timeStart);
                    this.user.setTermStart(new Date(timeStart.valueOf()));
                    this.timeFrameEnd = this.timeFrameStart.clone().add(termMonths, 'month').endOf('day');
                    const timeEnd = this.timeFrameEnd.toDate();
                    this.user.setTermEnd(new Date(timeEnd.valueOf()));
                    const routeUrl = this.router.url;
                    this.router.navigate([this.composeUrlForPreviousTerm(routeUrl)]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart,
                        end: this.timeFrameEnd
                    });
                } break;
            }
            if (enterKey) {
                const shortTimeout = 250;
                window.setTimeout(() => {
                    document.getElementById('display-date').focus();
                }, shortTimeout);
            }
            this.setTimeFormatMonth();
        }
    }

    incrementTimeSlice(enterKey: boolean): void {
        if (this.canIncrement) {
            switch (this.route.snapshot.paramMap.get('unit')) {
                case TimeFrameUnit.DAY:
                    this.router.navigate([this.composeUrlForNextDay()]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart.clone().add(1, 'day').startOf('day'),
                        end: this.timeFrameStart.clone().add(1, 'day').endOf('day')
                    });
                    break;

                case TimeFrameUnit.WEEK:
                    this.router.navigate([this.composeUrlForNextWeek()]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameEnd.clone().add(1, 'day').startOf('week'),
                        end: this.timeFrameEnd.clone().add(1, 'week')
                    });
                    break;

                case TimeFrameUnit.MONTH: {
                    const newTf = this.vehicle.getTermLimitsContainingMoment(this.timeFrameEnd.clone().add(1, 'day'));
                    const routeUrl = this.router.url;
                    this.router.navigate([this.composeUrlForNextMonth(newTf, routeUrl)]);
                    this.timeframeService.updateTimeframeRules(newTf);
                } break;
                case TimeFrameUnit.TERM: {
                    const newTf = this.vehicle.getTermLimitsContainingMoment(this.timeFrameEnd.clone().add(1, 'day'));
                    const routeUrl = this.router.url;
                    const start_date = new Date(this.user.getTermStart().valueOf());
                    const sixMonths = 6;
                    const twelveMonths = 12;
                    let termMonths = sixMonths;
                    termMonths = moment(this.user.getTermEnd()).diff(moment(this.user.getTermStart()), 'months') > sixMonths ? twelveMonths : sixMonths;
                    start_date.setMonth(start_date.getMonth() + termMonths);
                    const end_date = new Date(this.user.getTermEnd().valueOf());
                    end_date.setMonth(end_date.getMonth() + termMonths);
                    this.user.setTermStart(new Date(start_date.valueOf()));
                    this.user.setTermEnd(new Date(end_date.valueOf()));
                    this.timeFrameStart = moment(this.user.getTermStart());
                    this.timeFrameEnd = moment(this.user.getTermEnd());
                    this.router.navigate([this.composeUrlForNextTerm(newTf, routeUrl)]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart,
                        end: this.timeFrameEnd
                    });
                } break;
            }
            if (enterKey) {
                const shortTimeout = 250;
                window.setTimeout(() => {
                    document.getElementById('display-date').focus();
                }, shortTimeout);
            }
            this.setTimeFormatMonth();
        }
    }

    composeUrlForNextDay(): string {
        const date = moment.min(
            this.timeFrameStart.clone().add(1, 'day'),
            moment(this.vehicle.periodEnd)
        ).format('YYYYMMDD');
        return `/smiles/${date}/day/dailySummary`;
    }

    composeUrlForNextWeek(): string {
        const date = moment.min(
            this.timeFrameEnd.clone().add(1, 'day'),
            moment(this.vehicle.periodEnd)
        ).format('YYYYMMDD');
        this.timeFrameEnd = this.timeFrameEnd.clone().add(1, 'day');
        return `/smiles/${date}/week/weeklySummary`;
    }

    composeUrlForNextMonth(newTf, routeUrl): string {
        const date = moment.min(
            newTf.start,
            moment(this.vehicle.periodEnd)
        ).format('YYYYMMDD');
        return `/smiles/${date}/month/${routeUrl.substring(routeUrl.lastIndexOf('/') + 1)}`;
    }
    composeUrlForNextTerm(newTf, routeUrl): string {
        const date = moment.min(
            newTf.start,
            moment(this.vehicle.periodEnd)
        ).format('YYYYMMDD');
        return `/smiles/${date}/term/${routeUrl.substring(routeUrl.lastIndexOf('/') + 1)}`;
    }

    composeUrlForPreviousDay(): string {
        const date = moment.max(
            this.timeFrameStart.clone().subtract(1, 'day'),
            moment(this.vehicle.smEnrollDate)
        ).format('YYYYMMDD');
        return `/smiles/${date}/day/dailySummary`;
    }

    composeUrlForPreviousWeek(): string {
        const date = moment.max(
            this.timeFrameStart.clone().subtract(1, 'week').startOf('week'),
            moment(this.vehicle.smEnrollDate)
        ).format('YYYYMMDD');
        return `/smiles/${date}/week/weeklySummary`;
    }

    composeUrlForPreviousMonth(routeUrl): string {
        const date = moment.max(
            this.timeFrameStart,
            moment(this.vehicle.smEnrollDate)
        ).format('YYYYMMDD');
        return `/smiles/${date}/month/${routeUrl.substring(routeUrl.lastIndexOf('/') + 1)}`;
    }
    composeUrlForPreviousTerm(routeUrl): string {
        const date = moment.max(
            this.timeFrameStart,
            moment(this.vehicle.smEnrollDate)
        ).format('YYYYMMDD');
        return `/smiles/${date}/term/${routeUrl.substring(routeUrl.lastIndexOf('/') + 1)}`;
    }

    composeUrlForTodayGivenUnit(timeUnit: string): string {
        const date = moment(environment.futureDate).format('YYYYMMDD');
        const routeUrl = this.router.url;
        const newUrl = `/smiles/${date}/${timeUnit}/${routeUrl.substring(routeUrl.lastIndexOf('/') + 1)}`;
        return newUrl;
    }

    setTimeFormatMonth(): void {
        const december = 11;
        if (this.timeframe === TimeFrameUnit.MONTH) {
            this.timeFormat = 'LLL d';
            this.timeFormatStartMobile = 'LLL d';
        } else if (this.timeframe === TimeFrameUnit.WEEK || this.timeframe === TimeFrameUnit.TERM) {
            this.timeFormat = 'LLL d, yyyy';
            this.timeFormatStartMobile = 'LLL d, yyyy';
        }
    }

    setTimeNavigationRules(): void {
        const initialDate = moment.max(moment(this.vehicle.smEnrollDate), moment(this.vehicle.periodStart));
        const finalDate = moment(environment.futureDate);

        if (this.vehicle && this.timeFrameStart && this.timeFrameEnd) {
            if (this.timeframe === TimeFrameUnit.WEEK) {
                this.canIncrement = this.timeFrameEnd.clone().isBefore(finalDate, 'day'); // Allow navigation to partial weeks
                this.canDecrement = this.timeFrameStart.clone().isAfter(initialDate, 'day');
            } else if (this.timeframe === TimeFrameUnit.TERM) {
                if (this.user.getTermEnd().getTime() >= this.vehicle.periodEnd.getTime()) {
                    this.canIncrement = false;
                } else {
                    this.canIncrement = true;
                }

                if (this.user.getTermStart().getTime() < this.vehicle.periodStart.getTime()) {
                    this.canDecrement = false;
                } else {
                    this.canDecrement = true;
                }
            } else if (this.timeframe === TimeFrameUnit.DAY) {
                this.canIncrement = this.timeFrameStart.clone().add(1, <any> this.timeframe.valueOf()).isSameOrBefore(finalDate, 'day');
                this.canDecrement = this.timeFrameStart.clone().subtract(1, <any> this.timeframe.valueOf()).isSameOrAfter(initialDate, 'day');
            } else {
                this.canIncrement = this.timeFrameStart.clone().add(1, <any> this.timeframe.valueOf()).isSameOrBefore(finalDate, 'day');
                this.canDecrement = this.timeFrameEnd.clone().subtract(1, <any> this.timeframe.valueOf()).isSameOrAfter(initialDate, 'day');
            }
        }
    }

    redirectToCurrentDay(timeUnit: string): void {
        if (moment(environment.futureDate).isBefore(moment(this.vehicle.periodEnd))) {
            const newRoute = this.composeUrlForTodayGivenUnit(timeUnit);
            this.router.navigateByUrl(newRoute, { replaceUrl: true });
        }
    }

    private shouldRedirectToCurrentDay(timeUnit: string, dateToCheck: string): boolean {
        const enrollDate = moment(this.vehicle.smEnrollDate);
        const today = moment(environment.futureDate);
        return timeUnit !== TimeFrameUnit.TERM &&
            (moment(dateToCheck).isAfter(today, 'day') || moment(dateToCheck).isBefore(enrollDate, 'day'));
    }
}
