import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UserService } from 'app/service/user.service';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap/accordion/accordion';
import { ActivatedRoute, Router } from '@angular/router';
import { EbiEventService } from 'app/service/ebi-event.service';
import { Vehicle } from 'app/model/vehicle';
import { BrandingService } from 'app/service/branding.service';
import { NavbarService } from 'app/service/nav-bar.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})

export class HelpComponent implements OnInit {
  @ViewChild('smiles-text-10', { static: false }) firstLinkToggle: ElementRef;
  @ViewChild('smiles-text-01', { static: false }) secondLinkToggle: ElementRef;
  @ViewChild('smiles-text-08', { static: false }) thirdLinkToggle: ElementRef;
  accordionRows = [];
  faqs = [];
  participationDiscount = '10%';
  maxDiscount = '40%';
  partDiscountMO = '5%';
  desktopViewImage = `<img src='assets/image/variable-mo-cost-desktop-view.png' class='graphImageDesktop' alt='graph based equation'>`;
  mobileViewImage = `<img src='assets/image/variable-mo-cost-mobile-view.png' class='graphImage' alt='graph mobile based equation'>`;
  scoringModel: string;
  vehicle: Vehicle;
  questionClicked = false;
  smcc: boolean;
  smartMilesState: boolean;
  innerWidth: number;
  mobileView: boolean;
  // eslint-disable-next-line no-magic-numbers
  maxMobileWidth = 768;

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.checkInnerWidth(window.innerWidth);
    }

  // eslint-disable-next-line max-params
  constructor(
    public http: HttpClient,
    public user: UserService,
    private activeRoute: ActivatedRoute,
    public ebiEvents: EbiEventService,
    public navbar: NavbarService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.vehicle = this.activeRoute.snapshot.data.vehicle;
    this.user.selectedVIN = this.vehicle.vin;
    this.scoringModel = this.vehicle.scoringModel;
    this.navbar.headerDisplay(this.user.isLoggedIn, this.router.url);
    if (window.innerWidth < this.maxMobileWidth) {
        this.mobileView = true;
    } else {
        this.mobileView = false;
    }
    this.jsonUpdate();
    if (BrandingService.getBranding() === 'smartride') {
      this.ebiEvents.postSrideEvent('425080041');
    } else {
      this.ebiEvents.postEvent('Help');
    }
  }

  jsonUpdate(): any {
    this.getJSON().subscribe((data) => {
        this.faqs = data;
        this.setupAccordion();
      });
  }

  getJSON(): Observable<any> {
    this.smartMilesState = false;
    // Display specific FAQ based on vehicle scoring model
    const policyType = sessionStorage.getItem('LoginBranding');
    if (this.vehicle.scoringModel === 'SM2') {
        this.smartMilesState = true;
        if (this.vehicle.vendorIdCode === 'IMS') {
            return this.http.get('/assets/faq-SM2.0.json');
        } else if (this.vehicle.vendorIdCode === 'FORD' || this.vehicle.vendorIdCode === 'TOYOTA') {
            this.smcc = true;
            return this.http.get('/assets/faq-SMCC2.0.json');
        }
    } else if ((this.vehicle.vendorIdCode === 'FORD' || this.vehicle.vendorIdCode === 'TOYOTA') && this.scoringModel === 'SM1') {
      this.smcc = true;
      return this.http.get('/assets/faq-SMCC.json');
    } else if (this.scoringModel === 'TW' || this.scoringModel === 'TW1') {
      return this.http.get('/assets/faq-TW.json');
    } else if (this.scoringModel === 'TW2' || this.scoringModel === 'ND1') {
      return this.http.get('/assets/faq-ND1.json');
    } else if (this.scoringModel === 'MO') {
      return this.http.get('/assets/faq-MO.json');
    } else if (this.scoringModel === 'SM1') {
      return this.http.get('/assets/faq-SM.json');
    }
    return throwError(`Invalid scoring model: ${this.scoringModel}`);
  }

  setupAccordion(): void {
    for (const faq of this.faqs) {
      const sectionRows = [];
      for (const question of faq.questions) {
        sectionRows.push(false);
        question.answer = question.answer.replace(/{vin}/g, this.vehicle.vin);
        // insert the appropriate discount percentages
        if (this.scoringModel === 'MO') {
          question.answer = question.answer.replace(/{partDiscount}/g, this.partDiscountMO);
        } else {
          question.answer = question.answer.replace(/{partDiscount}/g, this.participationDiscount);
        }
        question.answer = question.answer.replace(/{maxDiscount}/g, this.maxDiscount);
        if (this.mobileView) {
            question.answer = question.answer.replace(/{image}/g, this.mobileViewImage);
        } else {
            question.answer = question.answer.replace(/{image}/g, this.desktopViewImage);
        }
      }
      this.accordionRows.push(sectionRows);
    }
    // Set first accordion to expanded when user lands on page
    if (this.accordionRows.length > 0) {
      this.accordionRows[0][0] = true;
    }
  }

  toggle(event: NgbPanelChangeEvent, section): void {
    for (const i in this.accordionRows[section]) {
      if (`toggle-${section}${i}` === event.panelId || `sre-toggle-${section}${i}` === event.panelId) {
        this.accordionRows[section][i] = !this.accordionRows[section][i];
      } else {
        this.accordionRows[section][i] = false;
      }
    }
  }

  headerLink(linkNumber: number): void {
    this.questionClicked = true;
    this.closeAllAccordions();
    switch (linkNumber) {
      case 1:
        document.getElementById('sre-card-title-22').click();
        window.setTimeout(() => {
          document.getElementById('sre-card-title-22').scrollIntoView(true);
          document.getElementById('sre-card-title-22').focus();
        }, 1);
        break;
      case 2:
        document.getElementById('sre-card-title-01').click();
        window.setTimeout(() => {
          document.getElementById('sre-card-title-01').scrollIntoView(true);
          document.getElementById('sre-card-title-01').focus();
        }, 1);
        break;
      // eslint-disable-next-line no-magic-numbers
      case 3:
        if (this.smartMilesState) {
            document.getElementById('sre-card-title-07').click();
            window.setTimeout(() => {
            document.getElementById('sre-card-title-07').scrollIntoView(true);
            document.getElementById('sre-card-title-07').focus();
            }, 1);
            break;
        } else {
            document.getElementById('sre-card-title-08').click();
            window.setTimeout(() => {
            document.getElementById('sre-card-title-08').scrollIntoView(true);
            document.getElementById('sre-card-title-08').focus();
            }, 1);
            break;
        }
      // eslint-disable-next-line no-magic-numbers
      case 4:
        document.getElementById('sre-card-title-111').click();
        window.setTimeout(() => {
          document.getElementById('sre-card-title-111').scrollIntoView(true);
          document.getElementById('sre-card-title-111').focus();
        }, 1);
        break;
      // eslint-disable-next-line no-magic-numbers
      case 6:
        document.getElementById('sre-card-title-13').click();
        window.setTimeout(() => {
          document.getElementById('sre-card-title-13').scrollIntoView(true);
          document.getElementById('sre-card-title-13').focus();
        }, 1);
        break;
      // eslint-disable-next-line no-magic-numbers
      case 5: // User doesn't see what they are looking for. Don't focus any specific question.
        break;
    }
  }

  closeAllAccordions(): void {
    // set accordionRows to 'All are closed'
    for (const section of this.accordionRows) {
      section.fill(false, 0, section.length);
    }

    // Find all open accordions and click them closed
    const openAccordions = <any[]>Array
      .from(document.getElementsByClassName('btn-link'))
      .filter((elem) => !elem.classList.contains('collapsed'));
    for (const accordion of openAccordions) {
      accordion.click();
    }
  }

  checkInnerWidth(screenWidth: number): void {
        this.mobileView = false;
        if (screenWidth < this.maxMobileWidth) {
            this.mobileView = true;
        }
        this.jsonUpdate();
    }
}
