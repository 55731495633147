// TODO: Pointing to the stage nationwide.com page doesn't help much as those still link to prod pages
/* eslint-disable sre/no-hardcoded-nw-url */
import { environmentCommon } from './environment.common';
import { timeout } from 'app/session-time-out/app-service-config';

/* eslint-disable camelcase */

export const environment = {
    ...environmentCommon,
    apiKey: 'edArQOvJTbGjUQwoKmUsfsB7f7oAMvkN',
    envName: 'stage',
    sreApiURL: 'https://api-test.nwie.net/policymanagement/personallines/experience/v2/',
    apigeeExtHostnameURL: 'https://api-test.nwie.net/',
    policyCenterShortName: 'pls12',
    // eslint-disable-next-line no-magic-numbers
    futureDate: new Date(2025, 2, 10, 13, 13, 13, 13),
    CLIENT_LOGGING_API: {
        ENDPOINT: 'https://api-test.nwie.net/it-management/client-logging/v1/client-logs',
        PUSH_LENGTH: 1
    },
    authorize: {
        oauthEndpoint: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize',
        client_id: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5',
        nonce: 'myNonce',
        ttl: 1200,
        redirectUri: 'https://www.sre.t.awspubliccloud.nationwide.com/',
        responseType: 'id_token token',
        scope: 'test',
        realm: 'member',
        loginTokenParam: '',
        tokenStorage: 'tokenDetails',
        idTokenStorage: 'id_token',
        authTimeout: 60,
        debug: true,
        excludedRoutes: ['/excluded', '/smartmiles/login', '/smartride/login', '/smartride/login?message=expired&error=',
            '/smartmiles/login?message=expired&error=', '/smartmiles/login?message=loggedout&error=',
            '/smartride/login?message=loggedout&error=', '/not-found', '//index.html', '/index.html',
            '/smartmiles', '/smartride', '/smartmiles/login?message=&error=error', '/smartride/login?message=&error=error',
            '/smartride/login?message=&error=', '/smartmiles/login?message=&error='],
        refreshThresholdSeconds: timeout.secondsToTimeoutWarning,
        loginTokenSessionStorageLocation: '',
        auth_method: 'ping',
        nwie_session_key: 'NWIESESSION',
        agentClient_id: 'z3P2gOks4zbiRAQyzTGKOu8gsQE4hRJE',
        agentState: 'requestedRoute=/home/start?agencyChannel=IA',
        agentNonce: 'fboA9sTH999kXr6eofuKo3y9hZTKUrAx',
        agentResponseType: 'token',
        agentScope: 'openid',
        agentRealm: 'distribution-partner',
        agentAuthMethod: 'ping-racf-ia',
        agentIdentityMethod: 'ac'
    },
    ratedMileageService: {
        url: 'https://api-test.nwie.net/policy-management/telematics-program-enrollments/v1/policies/POLICY/vehicles/VIN/rated-mileages' +
            '?ratedMileageStartDate=STARTDATE&ratedMileageEndDate=ENDDATE',
        apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
    },

    srmUserDetailsApiUrl: {
        url: 'https://api-test.nwie.net/policymanagement/personallines/experience/v2/mobile',
        apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
    },

    termsAndConditionAcceptance: {
      url: 'https://api-test.nwie.net/policymanagement/personallines/experience/v2/' +
              '/policies/POLICY/vehicles/VIN/acceptances'
    },

    srpProgramDetails: {
      url: 'https://api-test.nwie.net/policymanagement/personallines/experience/v2/policies/POLICY/program-details'
    },

    ebiEventService: {
        url: 'https://api-test.nwie.net/businessintelligence/enterprisebusinessintelligence/v1/log',
        apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5',
        eventId: '425081001'
    },

    personalLinesPolicyService: {
        url: 'https://api-test.nwie.net/policy-management/auto-policies/v1',
        xNwTargetEnv: 'pls12'
    },
    telematicsTransactionsService: {
      url: 'https://api-int-test.nwie.net/policy-management/telematics/v1/policies/POLICY/vehicles/VIN/estimated-premium' +
        '?estimatedPremiumStartDate=STARTDATE&estimatedPremiumEndDate=ENDDATE',
      apiKey: 'cnuBxAPjeO5tOJBWXkKAqVEm3SUadAGP'
    },

    telematicsService: {
        url: 'https://api-test.nwie.net/policy-management/telematics/v1',
        apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
    },
    apigeeEUAPingService: {
        url: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize',
        apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
    },
    oauthRevocationService: {
        url: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/revoke',
        apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
    },
    policyPreferencesService: {
        url: 'https://api-test.nwie.net/customerrelationshipmanagement/preferencesmanagement/v2/customers',
        apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
    },
    ecifService: {
        url: 'https://api-test.nwie.net/customer-information-management/enterprise-customer/v1/customers/'
    },
    telematicsProgramEnrollmentsService: {
        url: 'https://api-test.nwie.net/policy-management/telematics-program-enrollments/v1/policies/POLICYNUMBER/final-discounts',
        apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
    },
    imports: []

};
