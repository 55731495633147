import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'app/service/user.service';
import { Router } from '@angular/router';
import { Vehicle } from 'app/model/vehicle';
import { EbiEventService } from 'app/service/ebi-event.service';
import { TimeFrameUnit } from 'app/enum/timeframe';
import * as moment from 'moment';
import { TimeframeService } from 'app/service/timeframe.service';
import { Moment } from 'moment';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { BrandingService } from 'app/service/branding.service';
import { Alert } from 'app/enum/alert';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-program-select-card',
    templateUrl: './program-select-card.component.html',
    styleUrls: ['./program-select-card.component.css']
})
export class ProgramSelectCardComponent implements OnInit {
    @Input() vehicle: Vehicle;
    @Input() idCounter: number;
    programSRE: boolean;
    pendingRemoval: boolean;
    deviceActive: boolean;
    fortyFiveDays: boolean;
    cardClass: string;
    discountHasBeenRemoved: boolean;
    discountWillBeRemoved: boolean;
    showReturnTab: boolean;
    discountInRemoval: boolean;
    totalMilesInTerm: number;
    termStartDate: Date;
    termEndDate: Date;
    deviceStatus: string;
    deviceStatus_firstPart: string;
    deviceStatus_lastPart: string;
    deviceIconUrl: string;
    isHeartbeatIndicatorActive = false;

    // eslint-disable-next-line max-params
    constructor(
        private user: UserService,
        private router: Router,
        public ebiEvents: EbiEventService,
        public timeframe: TimeframeService,
        public mileage: RatedMileageService
    ) { }

    ngOnInit(): void {
        if (this.vehicle) {
            this.setDisplayVariables();
            this.getTotalMilesinTimeFrame();
            sessionStorage.setItem('selectedPolicy', this.vehicle.policyNum);
        } else {
            this.vehicle = new Vehicle();
            this.vehicle.vehicleDataUnavailable = true;
        }
    }

    redirect(type: string): boolean {
        if (!this.navigationEnabled()) {
            return;
        }

        this.user.selectedVin = this.vehicle.vin;
        this.user.programType = this.vehicle.programType;
        this.ebiEvents.postEvent('SmartMiles pay per mile'); // TODO are we really supposed to send this for SmartRide policies? We do.
        this.brandByScoringModel(this.vehicle.type);

        let start: Moment;
        if (this.vehicle.enrolledMidTerm) {
            this.timeframe.updateTimeframeRules(this.vehicle.getTermLimitsContainingMoment(moment(environment.futureDate)));
        } else {
            start = moment(this.vehicle.periodStart).endOf('day');
            this.timeframe.updateTimeframeRules(<TimeFrame>{
                start,
                end: start.clone().add(1, 'month').subtract(1, 'day').endOf('day'),
                unit: TimeFrameUnit.MONTH
            });
        }
        // Set the alert type to grace period if the rated mileage response says so
        if (this.mileage.determineIfGracePeriod(this.vehicle.ratedMileage)) {
            this.user.alertType = Alert.GracePeriod;
        }
        if (this.vehicle.firstLogin && !this.user.isAdmin() && (this.vehicle.scoringModel !== 'SM1' && this.vehicle.scoringModel !== 'SM2') && this.vehicle.programType !== 'TC') {
            if (!sessionStorage.getItem('showedWelcomePage')) {
                sessionStorage.setItem('showedWelcomePage', 'true');
                this.router.navigateByUrl(`/sride/welcomePage`);
                return;
            }
        }

        if (type === 'sride') {
            this.ebiEvents.assignProgramType(this.vehicle.programType);
            this.ebiEvents.postSrideEvent('425080003');
        }

        if (type === 'smiles') {
            if (!this.vehicle.smEnrollDate) {
                this.router.navigateByUrl(`/nw/systemError`, { replaceUrl: true });
                return;
            }
            const showMonth = moment.min(moment(this.vehicle.periodEnd), moment(environment.futureDate)).format('YYYYMMDD');
            this.router.navigateByUrl(`/${type}/${showMonth}/month/monthlySummary`);
        } else {
            this.router.navigateByUrl(`/${type}/summary/month/latest`);
        }
        return true;
    }

    calcClass(): string {
        let cardClass: string;
        cardClass = 'card height-container vehicle-card';
        if (this.vehicle.scoringModel !== 'SM1' && this.vehicle.scoringModel !== 'SM2') {
            if (!this.isFinalDiscount()) {
                cardClass = `${cardClass} sr-view`;
            } else if (this.isFortyFiveDays() !== true && !this.pendingRemoval &&
                !this.discountWillBeRemoved && !this.discountHasBeenRemoved) {
                cardClass = `${cardClass} final-discount-view`;
            }
        } else if (this.vehicle.scoringModel === 'SM1' || this.vehicle.scoringModel === 'SM2') {
            cardClass = `${cardClass} sm-view`;
        }
        if (this.isFinalDiscount() && (this.pendingRemoval || this.discountWillBeRemoved || this.discountHasBeenRemoved)) {
            cardClass = `${cardClass} last-policy-discount-driver-view`;
        }
        if (this.showReturnTab) {
            cardClass = `${cardClass} return-tab`;
        }
        if (!this.navigationEnabled()) {
            cardClass += ' finished-view';
        }
        return cardClass;
    }

    navigationEnabled(): boolean {
        if (!this.programSRE && this.vehicle.ratedMileageDataUnavailable) {
            return false;
        }
        if (this.vehicle.vehicleDataUnavailable) {
            return false;
        }
        if (this.fortyFiveDays) {
            return false;
        }
        return true;
    }

    isFinalDiscount(): boolean {
        return (/final/i).test(this.vehicle.discountType);
    }

    isProgramSRE(): boolean {
        return this.vehicle.scoringModel !== 'SM1' && this.vehicle.scoringModel !== 'SM2';
    }

    isDeviceActive(): boolean {
        // Heartbeat indicator will always show as false as current day because batch job doesnt run untill midnight
        const previousDayIndex = 1;
        let isActive = false;
        this.isHeartbeatIndicatorActive = false;
        // In order to check yesterdays call, we need to make sure the length of ratedMileage is 2 or more.
        if (this.vehicle?.device) {
            if (!this.isProgramSRE() && this.vehicle.ratedMileage.length > 1) {
                if (!this.vehicle.ratedMileageDataUnavailable && (this.vehicle.ratedMileage[previousDayIndex].heartbeatIndicator || this.vehicle.ratedMileage[previousDayIndex + 1].heartbeatIndicator)) {
                    this.isHeartbeatIndicatorActive = true;
                    isActive = this.getDeviceActiveStatus();
                }
            } else {
                isActive = this.vehicle.device.status.toLowerCase().includes('active');
            }
        }
        return isActive;
    }

    isDeviceReturned(): boolean {
        if (!this.vehicle.device) {
            return false;
        }
        return this.vehicle.device.status === 'Device Returned' || this.vehicle.device.status === 'Device: Returned';
    }

    isPendingRemoval(): boolean {
        return false;
    }

    isFortyFiveDays(): boolean {
        const policyType = sessionStorage.getItem('LoginBranding');
        if (this.user.isAdmin() || (!this.vehicle.discounts || this.vehicle.discounts.length === 0)) {
            return false;
        }
        if (policyType === 'smartride') {
            if (!(/final/i).test(this.vehicle.discountType)) {
                return false;
            }
            for (const discount of this.vehicle.discounts) {
                if ((/final/i).test(discount.discountType)) {
                    // eslint-disable-next-line no-magic-numbers
                    return moment(environment.futureDate).subtract(45, 'day').isAfter(discount.date);
                }
            }
        }
        return false;
    }

    isDiscountInAnyPartOfRemoval(): boolean {
        if (this.user.isAdmin()) {
            return false;
        }
        return (/removed/i).test(this.vehicle.discountType);
    }

    isReturnTabShown(): boolean {
        return this.isFinalDiscount() && !this.isDeviceReturned();
    }

    getLogoDiscriminator(): string {
        if (this.programSRE) {
            if (
                this.pendingRemoval ||
                this.discountWillBeRemoved ||
                this.discountHasBeenRemoved ||
                this.vehicle.vehicleDataUnavailable ||
                this.isFortyFiveDays()
            ) {
                return 'sr-grey';
            } else if (this.isFinalDiscount()) {
                return 'sr-white';
            } else {
                return 'sr-color';
            }
        } else if (
            this.vehicle.ratedMileageDataUnavailable ||
            this.vehicle.vehicleDataUnavailable
        ) {
            return 'sm-grey';
        } else {
            return 'sm-color';
        }
    }

    getUrlForLogo(): string {
        switch (this.getLogoDiscriminator()) {
            case 'sr-grey': return '/assets/image/svg/sr-logo-grey.svg';
            case 'sr-white': return '/assets/image/svg/sr-logo-white.svg';
            case 'sr-color': return '/assets/image/svg/sr-logo-color.svg';
            case 'sm-grey': return '/assets/image/svg/sm-logo-grey.svg';
            case 'sm-color': return '/assets/image/svg/sm-logo-color.svg';
        }
    }

    getAltTextForLogo(): string {
        if (this.programSRE) {
            return 'Smart Ride Logo';
        } else {
            return 'Smart Miles Logo';
        }
    }

    getElementIdForLogo(): string {
        let prefix: string;
        switch (this.getLogoDiscriminator()) {
            case 'sr-grey': prefix = 'sre-logo-grey'; break;
            case 'sr-white': prefix = 'sre-logo-white'; break;
            case 'sr-color': prefix = 'sre-logo-color'; break;
            case 'sm-grey': prefix = 'smiles-logo-grey'; break;
            case 'sm-color': prefix = 'smiles-logo-color'; break;
        }
        return `${prefix}-${this.idCounter}`;
    }

    getAltTextForIcon(): string {
        if (this.programSRE) {
            return 'Smart Ride Icon';
        } else {
            return 'Smart Miles Icon';
        }
    }

    getElementIdForIcon(): string {
        let prefix: string;
        switch (this.getLogoDiscriminator()) {
            case 'sr-grey': prefix = 'sride-icon-grey'; break;
            case 'sr-white': prefix = 'sride-icon-white'; break;
            case 'sr-color': prefix = 'sride-icon-color'; break;
            case 'sm-grey': prefix = 'smiles-icon-grey'; break;
            case 'sm-color': prefix = 'smiles-icon-color'; break;
        }
        return `${prefix}-${this.idCounter}`;
    }

    getUrlForIcon(): string {
        switch (this.getLogoDiscriminator()) {
            case 'sr-grey': return '/assets/image/svg/sr-icon-fill-grey.svg';
            case 'sr-white': return '/assets/image/svg/sr-icon-fill-white.svg';
            case 'sr-color': return '/assets/image/svg/sr-icon-medium.svg';
            case 'sm-grey': return '/assets/image/svg/sm-icon-fill-grey.svg';
            case 'sm-color': return '/assets/image/svg/sm-icon-fill-color.svg';
        }
    }

    shouldShowDiscountSection(): boolean {
        if (this.programSRE) {
            if (this.vehicle.vehicleDataUnavailable) {
                return false;
            }
            return true;
        } else {
            if (this.vehicle.ratedMileageDataUnavailable) {
                return true;
            }
            if (this.vehicle.vehicleDataUnavailable) {
                return true;
            }
            return false;
        }
    }

    shouldShowDiscountPercentage(): boolean {
        if (this.discountInRemoval) {
            return false;
        }
        if (this.vehicle.discountPercent === undefined) {
            return false;
        }
        if (!this.programSRE) {
            return false;
        }
        return this.vehicle.discountType !== 'Participation Discount';
    }

    shouldShowFinalDiscountSection(): boolean {
        if (this.discountWillBeRemoved) {
            return true;
        }
        if (this.discountHasBeenRemoved) {
            return true;
        }
        if (!this.programSRE && this.vehicle.ratedMileageDataUnavailable) {
            return true;
        }
        if (this.vehicle.vehicleDataUnavailable) {
            return true;
        }
        if (this.fortyFiveDays) {
            return true;
        }
        return false;
    }

    shouldShowFinalDiscountLock(): boolean {
        if (this.discountInRemoval) {
            return false;
        }
        if (this.fortyFiveDays) {
            return true;
        }
        return true;
    }

    getUrlForFinalDiscountLockImage(): string {
        if (!this.navigationEnabled()) {
            return '/assets/image/svg/lock-grey.svg';
        }
        return '/assets/image/svg/lock-policy.svg';
    }

    shouldShowFinalDiscountMessage(): boolean {
        if (this.discountInRemoval) {
            return false;
        }
        if (this.fortyFiveDays) {
            return true;
        }
        return true;
    }

    shouldShowDiscountHelpMessage(): boolean {
        if (this.discountWillBeRemoved) {
            return true;
        }
        if (this.discountHasBeenRemoved) {
            return true;
        }
        if (this.vehicle.vehicleDataUnavailable) {
            return true;
        }
        if (!this.programSRE && this.vehicle.ratedMileageDataUnavailable) {
            return true;
        }
        return false;
    }

    shouldShowMilesDriven(): boolean {
        if (this.programSRE) {
            return false;
        }
        if (this.discountInRemoval) {
            return false;
        }
        if (this.vehicle.ratedMileageDataUnavailable) {
            return false;
        }
        if (this.vehicle.vehicleDataUnavailable) {
            return false;
        }
        return true;
    }

    shouldShowFinalDiscountDisclaimer(): boolean {
        if (!this.isFinalDiscount()) {
            return false;
        }
        if (this.fortyFiveDays) {
            return false;
        }
        if (this.pendingRemoval) {
            return false;
        }
        if (this.discountWillBeRemoved) {
            return false;
        }
        if (this.discountHasBeenRemoved) {
            return false;
        }
        return true;
    }

    shouldShowDeviceStatus(): boolean {
        if (this.vehicle.programType === 'TC') {
            return false;
        }
        if (this.isFinalDiscount()) {
            return false;
        }
        if (!this.vehicle.vehicleDataUnavailable) {
            return true;
        }
        if (this.programSRE && this.vehicle.ratedMileageDataUnavailable) {
            return true;
        }
        if (!this.vehicle.ratedMileageDataUnavailable) {
            return true;
        }
        return false;
    }

    getDeviceStatusDiscriminator(): string {
        if (this.isDeviceReturned()) {
            if (this.pendingRemoval) {
                return 'device-grey-success';
            }
            if (this.isFinalDiscount() || this.fortyFiveDays) {
                return 'device-grey-success';
            }
        }
        if (this.isFinalDiscount()) {
            if (!this.fortyFiveDays) {
                if (!this.deviceActive && !this.discountInRemoval) {
                    return 'device-white-fail';
                }
                if (this.deviceActive && !this.pendingRemoval) {
                    return 'device-white-success';
                }
            }
        } else if (!this.pendingRemoval) {
            if (this.vehicle.device.status === 'Device:  Returned' ||
                this.vehicle.device.status === 'Device:  Shipped' ||
                this.vehicle.device.status === 'Device: Not Yet Shipped') {
                return 'device-color-none';
            } else if (this.deviceActive) {
                return 'device-color-success';
            } else {
                const start = moment(this.vehicle.periodStart).endOf('day');
                this.timeframe.updateTimeframeRules(<TimeFrame>{
                    start,
                    end: start.clone().add(1, 'month').subtract(1, 'day').endOf('day'),
                    unit: TimeFrameUnit.MONTH
                });
            }
            // Set the alert type to grace period if the rated mileage response says so
            if (this.mileage.determineIfGracePeriod(this.vehicle.ratedMileage)) {
                this.user.alertType = Alert.GracePeriod;
            }
        }
        // I don't believe this is reachable, but use a failure image as the default:
        return 'device-color-fail';
    }

    getElementIdForDeviceStatusIcon(): string {
        return `${this.getDeviceStatusDiscriminator()}-${this.idCounter}`;
    }

    getUrlForDeviceStatusIcon(): void {
        this.deviceIconUrl = `/assets/image/svg/${this.getDeviceStatusDiscriminator()}.svg`;
        this.setDeviceStatus(this.deviceActive);
        if (this.isConnectedCar()) {
            if (this.deviceStatus === 'Data status: Sharing') {
                this.deviceIconUrl = `/assets/image/CC_Active.png`;
            } else {
                this.deviceIconUrl = `/assets/image/CC_Inactive.png`;
            }
        }
    }

    setDisplayVariables(): void {
        this.programSRE = this.isProgramSRE();
        if (this.vehicle.device) {
            this.deviceActive = this.isDeviceActive();
            this.getUrlForDeviceStatusIcon();
        }
        this.pendingRemoval = this.isPendingRemoval();
        this.fortyFiveDays = this.isFortyFiveDays();
        this.discountWillBeRemoved = this.isDiscountInAnyPartOfRemoval();
        this.discountHasBeenRemoved = this.isDiscountInAnyPartOfRemoval();
        if (this.programSRE) {
            sessionStorage.setItem('vidcode', this.vehicle.vendorIdCode);
        }
        this.showReturnTab = this.isReturnTabShown();
        this.discountInRemoval = this.isDiscountInAnyPartOfRemoval();
        this.cardClass = this.calcClass();
    }

    brandByScoringModel(type: string): void {
        const brandingName = type === 'smiles' ? 'smartmiles' : 'smartride';
        BrandingService.setBranding(brandingName);
    }

    getTotalMilesinTimeFrame(): number {
        this.termStartDate = this.vehicle.periodStart;
        this.termEndDate = this.vehicle.periodEnd;

        const termTimeframe = <TimeFrame>{
            start: moment(this.termStartDate),
            end: moment(this.termEndDate),
            unit: TimeFrameUnit.TERM
        };
        this.totalMilesInTerm = this.vehicle.getTotalTripsInTimeframe(termTimeframe).map((trip) =>
            trip.ratedMileage || 0).reduce((acc, next) =>
                acc + next, 0);
        return this.totalMilesInTerm;
    }

    setDeviceStatus(datasharing: boolean): void {
        const firstPartEnd_device = 7;
        const firstPartEnd_CC = 12;
        this.deviceStatus = this.vehicle.device.status;
        if (this.isConnectedCar()) {
            this.deviceStatus = 'Data status: Not Sharing';
            if (datasharing) {
                this.deviceStatus = 'Data status: Sharing';
            }
            this.deviceStatus_firstPart = this.deviceStatus.slice(0, firstPartEnd_CC);
            this.deviceStatus_lastPart = this.deviceStatus.slice(firstPartEnd_CC);
            return;
        }
        if (!datasharing && this.deviceStatus.toLocaleLowerCase().includes('active')) {
            this.deviceStatus = 'Device: Installed';
        }
        this.deviceStatus_firstPart = this.deviceStatus.slice(0, firstPartEnd_device);
        this.deviceStatus_lastPart = this.deviceStatus.slice(firstPartEnd_device);
    }

    private isConnectedCar(): boolean {
        return !this.programSRE && (this.vehicle.vendorIdCode === 'FORD' || this.vehicle.vendorIdCode === 'TOYOTA');
    }

    getDeviceActiveStatus(): boolean {
        if (this.vehicle.device.status.toLowerCase().includes('installed') || this.vehicle.device.status.toLowerCase().includes('active')) {
            this.vehicle.device.status = 'Device: Active';
            if (!this.isHeartbeatIndicatorActive) {
                this.vehicle.device.status = 'Device: Installed';
            }
            return true;
        } else {
            this.deviceStatus = this.vehicle.device.status;
            if (this.isConnectedCar()) {
                sessionStorage.setItem('VehicleType', this.vehicle.make);
                this.deviceStatus = 'Data status: Not Sharing';
            }
            return false;
        }
    }
}
