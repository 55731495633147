import { Component, OnInit, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { NavigationService } from 'app/service/navigation.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { Title } from '@angular/platform-browser';
import { BrandingService } from 'app/service/branding.service';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { VehicleService } from 'app/service/vehicle.service';
import { LoggerService } from 'app/service/logger.service';

@Component({
    selector: 'app-smiles-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {
    @Output() navigationUrl: string;
    @Output() skip = new EventEmitter();
    showMenu: boolean;
    programType: string;
    scoringModel: string;
    vin: string;
    route: ActivatedRoute;
    routerSubscription: Subscription;
    isBlancoUser: boolean;
    blancoSubscription: Subscription;
    addHamburger: boolean;
    vendorIdCode: string;
    innerWidth: number;
    mobileView: boolean;
    agentView: boolean;
    hideMenu: boolean;
    mobileNumberUpdateScreen: boolean;
    adminView: boolean;
    loggedUserRole: any;

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.checkInnerWidth(window.innerWidth);
    }

    // eslint-disable-next-line max-params
    constructor(
        public nav: NavigationService,
        public user: UserService,
        public navBar: NavbarService,
        public router: Router,
        public ebiEvents: EbiEventService,
        public titleService: Title,
        public branding: BrandingService,
        public vehicleService: VehicleService,
        private logger: LoggerService
    ) {
        nav.url.subscribe((url) => {
            this.navigationUrl = url;
            this.setHamburger();
        });
        this.hideMenu = false;
    }

    ngOnInit(): void {
        this.adminView = false;
        if (sessionStorage.getItem('isAgent')) {
            this.agentView = true;
        }
        this.checkInnerWidth(window.innerWidth);
        this.blancoSubscription = this.vehicleService.currentVehicleEmitter.subscribe((response): void => {
            this.isBlancoUser = response.programType === 'TC';
            this.vendorIdCode = response.vendorIdCode;
        });
        this.showMenu = false;
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.mobileNumberUpdateScreen = false;
                this.vin = this.user.selectedVin || this.vin;
                if (event.url.match(/preferences/)) {
                    this.vin = event.url.substring(event.url.lastIndexOf('/') + 1);
                }
                if (event.url.match(/mobileNumberUpdate/)) {
                    this.mobileNumberUpdateScreen = true;
                    this.navBar.links = true;
                    this.navBar.logoType = 'nationwide';
                    if (sessionStorage.getItem('isAdmin')) {
                        this.adminView = true;
                    }
               }
            }
        });
        if (sessionStorage.getItem('isAgent') === 'true') {
          this.loggedUserRole = 'Agent';
        } else if (sessionStorage.getItem('isAdmin') === 'true') {
          this.loggedUserRole = 'Admin';
        } else {
          this.loggedUserRole = 'Member';
        }
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
            this.routerSubscription = null;
        }
        if (this.blancoSubscription) {
            this.blancoSubscription.unsubscribe();
            this.blancoSubscription = null;
        }
    }

    logout(): any {
        this.navBar.headerDisplay(this.user.isLoggedIn, this.router.url);
        if (this.router.url.match('programSelect')) {
            this.ebiEvents.postEvent('Vehicles landing logout');
        } else if (this.router.url.match('preferences')) {
            this.ebiEvents.postEvent('Pref logout');
        }
        this.user.logout();
        this.programType = BrandingService.getBranding();
        if (this.programType === 'sride' || this.programType === 'smartride') {
            this.setTitle('SmartRide');
            this.ebiEvents.postSrideEvent('425080047');
        } else {
            this.setTitle('SmartMiles');
        }
    }

    menuControl(): void {
        if (this.showMenu === true) {
            this.showMenu = false;
        }
    }

    setHamburger(): void {
        this.addHamburger = true;
        if (this.navigationUrl === '/programSelect' || this.navigationUrl.match(/\/selfEnrollment(.*)/) || this.navigationUrl === '/mobileNumberUpdate') {
            this.addHamburger = false;
        }
    }

    closeMenu(ebiEventToPost?: string): void {
        if (ebiEventToPost === 'My Accounts') {
            this.logger.info('User clicked on MyAccounts/Billing link in top navigation', {
                policyNumber: this.user.getPolicyNumber(),
                userId: this.user.getUsername(),
                programType: sessionStorage.getItem('selectedProgramType')
            });
        }
        this.showMenu = false;
        if (this.router.url.match('preferences') && ebiEventToPost) {
            this.ebiEvents.postEvent(ebiEventToPost);
        }
    }

    setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }

    navigateToVehicles(): void {
        this.showMenu = false;
        if (this.router.url.match('preferences')) {
            this.ebiEvents.postEvent('vehicles link');
        }
        sessionStorage.removeItem('selectedPolicy');
    }

    logoEbiEvent(): void {
        if (this.router.url.match('programSelect')) {
            this.ebiEvents.postEvent('Landing NW logo');
            this.ebiEvents.postSrideEvent('425080043');
        } else if (this.navBar.logoType === 'sride') {
            this.ebiEvents.postSrideEvent('425080045');
        }
    }

    getMyAccountUrl(): string {
        return environment.myAccountUrl;
    }

    getTermsUrl(): string {
        const policyType = sessionStorage.getItem('LoginBranding');
        if (this.isBlancoUser) {
            return environment.termsAndConditionsConnectedCarURL;
        }
        if (policyType === 'smartride') {
            return environment.termsAndConditionsSmartRideURL;
        }
        if (policyType === 'smartmiles') {
            if (this.vendorIdCode === 'FORD' || this.vendorIdCode === 'TOYOTA') {
                return environment.termsAndConditionsSMConnectedCarURL;
            } else {
                return environment.termsAndConditionsSmartMilesURL;
            }
        }
    }

    checkInnerWidth(screenWidth: number): void {
        this.mobileView = false;
        const maxMobileWidth = 768;
        if (screenWidth < maxMobileWidth) {
            this.mobileView = true;
        }
    }

    search(): void {
        this.hideMenu = true;
        this.logger.info('SRM Update Mobile Number - Clicked "Go Back to Search" button', {
            policyNumber: this.user.getPolicyNumber(),
            role: this.loggedUserRole
          });
        sessionStorage.removeItem('agentSelectedPolicy');
        this.router.navigateByUrl('/smartrideAgent?userRole=Agent');
    }

    getBillingUrl(): any {
        this.hideMenu = true;
        return environment.myAccountUrl;
    }

    logClickOnMobileNumberUpdate(): void {
      this.logger.info('SRM Update Mobile Number Accounts Billing button click', {
        policyNumber: this.user.getPolicyNumber(),
        role: this.loggedUserRole
      });
    }
}
