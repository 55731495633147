import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarService } from '../../service/nav-bar.service';
import { UserService } from '../../service/user.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { BrandingService } from '../../service/branding.service';
import { LoggerService } from 'app/service/logger.service';
import * as moment from 'moment';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-how-it-works',
    templateUrl: './how-it-works.component.html',
    styleUrls: ['./how-it-works.component.css']
})

export class HowItWorksComponent implements OnInit {
    programType: string;
    scoringModel: string;
    vin: string;

    footerDisplay: any[];

    pluginStrSr = 'Once you install the device, keep it plugged in until the SmartRide program end date.';
    driveStrSr = 'Drive as you normally do. Your first trip may take up to 24 hours to show as Active on our website.';
    trackProgressStrSr = 'Get personalized feedback about your driving trends on our website or in your weekly summary emails.';
    lockDiscountStrSr = 'At the end of the program, simply send back your device and we\'ll calculate your ' +
        'final discount. It will be applied at your next policy renewal.';

    milesDrivenStrSr = 'The more miles you put on your car, the more likely it is you will have an accident. ' +
        'Plus, keeping mileage down saves gas and money on car repairs.';

    nightTimeDrivingStrSr = 'Driving between midnight and 5 a.m. increases your chances of having an accident.' +
        ' Drivers during these times may be tired or distracted.';

    idleTimeStrSr = 'Measures the length of time stopped while in a trip. This could be an indication of traffic congestion, ' +
        'which increases the chance of an accident.';

    hardBreakFastAccelStrSr = 'These are sudden stops and quick starts in excess of 7.7 mph per second. They ' +
        'could be a sign of aggressive driving.';

    hardBreakStrSr = 'Hard braking means forcing the vehicle to suddenly stop or decelerate in excess of 7.7 ' +
        'mph per second. Safe drivers will see very few instances of hard braking.';

    fastAccelStrSr = 'Fast acceleration is abruptly speeding more than 7.7 mph per second. It can be a sign of aggressive driving.';

    footerHeader = 'Your driving trends will determine your discount, based on 4 factors:';

    milesDrivenHeaderSr = 'Miles Driven';
    nightTimeDrivingHeaderSr = 'Nighttime Driving';
    idleTimeHeaderSr = 'Idle Time';
    hardBreakHeaderSr = 'Hard Braking';
    fastAccelHeaderSr = 'Fast Acceleration';
    hardBreakAccelHeaderSr = 'Hard Braking / Acceleration';

    milesDrivenImg = 'assets/image/smartride/icon_miles-white.png';
    nightTimeDrivingImg = 'assets/image/smartride/icon_night-white.png';
    idleTimeImg = 'assets/image/smartride/icon_idle-white.png';
    hardBreakImg = 'assets/image/smartride/icon_braking-white.png';
    fastAccelImg = 'assets/image/smartride/icon_acceleration-white.png';
    smartMilesConnectedCar: boolean;
    smartMilesConnectedCarCA: boolean;
    vendorIdCode: string;
    vehicleState: string;
    smilesVersionType: string;
    vehiclePeriodEnd;
    vehicleType: string;
    innerWidth: number;
    mobileView: boolean;
  // eslint-disable-next-line no-magic-numbers
    maxMobileWidth = 768;
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.checkInnerWidth(window.innerWidth);
    }

    // eslint-disable-next-line max-params
    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public user: UserService,
        public navBar: NavbarService,
        public ebiEvents: EbiEventService,
        private logger: LoggerService,
        public branding: BrandingService
    ) { }

    setFooterDisplay(scoringModel: string): void {
        if (scoringModel === 'MO') {
            this.footerHeader = 'Your discount is based on the number of miles you drive.';
            this.footerDisplay = [
                { image: this.milesDrivenImg, header: this.milesDrivenHeaderSr, body: this.milesDrivenStrSr, alt: 'smart ride miles driven image' }
            ];
        } else if (scoringModel === 'ND1' || scoringModel === 'TW2') {
            this.footerDisplay = [
                { image: this.milesDrivenImg, header: this.milesDrivenHeaderSr, body: this.milesDrivenStrSr, alt: 'smart ride miles driven image' },
                {
                    image: this.nightTimeDrivingImg, header: this.nightTimeDrivingHeaderSr, body: this.nightTimeDrivingStrSr,
                    alt: 'smart ride night time driving image'
                },
                { image: this.idleTimeImg, header: this.idleTimeHeaderSr, body: this.idleTimeStrSr, alt: 'smart ride idle time image' },
                {
                    image: this.hardBreakImg, header: this.hardBreakAccelHeaderSr, body: this.hardBreakFastAccelStrSr,
                    alt: 'smart ride hard breaking image'
                }
            ];
        } else if (scoringModel === 'TW' || scoringModel === 'TW1') {
            this.footerDisplay = [
                { image: this.milesDrivenImg, header: this.milesDrivenHeaderSr, body: this.milesDrivenStrSr, alt: 'smart ride miles driven image' },
                { image: this.hardBreakImg, header: this.hardBreakHeaderSr, body: this.hardBreakStrSr, alt: 'smart ride hard breaking image' },
                { image: this.fastAccelImg, header: this.fastAccelHeaderSr, body: this.fastAccelStrSr, alt: 'smart ride fast acceleration image' },
                {
                    image: this.nightTimeDrivingImg, header: this.nightTimeDrivingHeaderSr, body: this.nightTimeDrivingStrSr,
                    alt: 'smart ride night time driving image'
                }
            ];
        } else {
            this.footerDisplay = [
                { image: this.milesDrivenImg, header: this.milesDrivenHeaderSr, body: this.milesDrivenStrSr, alt: 'smart ride miles driven image' },
                { image: this.hardBreakImg, header: this.hardBreakHeaderSr, body: this.hardBreakStrSr, alt: 'smart ride hard breaking image' },
                { image: this.fastAccelImg, header: this.fastAccelHeaderSr, body: this.fastAccelStrSr, alt: 'smart ride fast acceleration image' },
                {
                    image: this.nightTimeDrivingImg, header: this.nightTimeDrivingHeaderSr, body: this.nightTimeDrivingStrSr,
                    alt: 'smart ride night time driving image'
                }
            ];
        }
    }

    ngOnInit(): void {
        this.smartMilesConnectedCar = false;
        this.smartMilesConnectedCarCA = false;
        this.programType = BrandingService.getBranding();
        this.scoringModel = this.route.snapshot.data.vehicle.scoringModel;
        this.vin = this.route.snapshot.data.vehicle.vin;
        this.vendorIdCode = this.route.snapshot.data.vehicle.vendorIdCode;
        this.vehicleState = this.route.snapshot.data.vehicle.state;
        this.vehiclePeriodEnd = this.route.snapshot.data.vehicle.periodEnd;
        this.vehicleType = this.route.snapshot.data.vehicle.type;
        this.determinesmartMilesConnectedCar();
        if (this.scoringModel && this.programType) {
            this.navBar.headerDisplay(this.user.isLoggedIn, this.router.url);
            this.setFooterDisplay(this.scoringModel);
            if (this.programType === 'smartride') {
                this.ebiEvents.postSrideEvent('425080075');
            }
            this.ebiEvents.postEvent('Program overview');
        } else {
            this.logger.error(`HowItWorksComponent: scoringModel=${this.scoringModel}, programType=${this.programType}`);
            this.router.navigateByUrl('nw/systemError');
        }
        if (this.scoringModel === 'SM2') {
            this.smilesVersionType = '2.0';
        }
    }

    selectTab = (event: NgbNavChangeEvent): void => {
        if (event.nextId === 'program-overview') {
            this.ebiEvents.postEvent('Program overview');
        } else if (event.nextId === 'terms-conditions') {
            this.ebiEvents.postEvent('T\'s and C\'s');
        }
    };
    determinesmartMilesConnectedCar(): void {
        if (this.programType === 'smartmiles') {
            if ((this.vendorIdCode === 'FORD' || this.vendorIdCode === 'TOYOTA') && this.vehicleState === 'CA') {
                this.smartMilesConnectedCarCA = true;
            } else if (this.vendorIdCode === 'FORD' || this.vendorIdCode === 'TOYOTA') {
                this.smartMilesConnectedCar = true;
            }
        }
    }

    logEbi(eventName: string): void {
        if (eventName === 'video') {
            this.ebiEvents.postSrideEvent('425080077');
        } else if (eventName === 'port') {
            this.ebiEvents.postSrideEvent('425080078');
        } else if (eventName === 'return') {
            this.ebiEvents.postSrideEvent('425080079');
        }
    }

    setTimeFrame(timeframe: string): void {
        const showMonth = moment.min(moment(this.vehiclePeriodEnd), moment(environment.futureDate)).format('YYYYMMDD');
        if (timeframe === 'MONTH') {
            this.router.navigateByUrl(`${this.vehicleType}/${showMonth}/month/monthlySummary`, { replaceUrl: true });
        } else if (timeframe === 'WEEK') {
            this.router.navigateByUrl(`${this.vehicleType}/${showMonth}/week/weeklySummary`, { replaceUrl: true });
        } else if (timeframe === 'TERM') {
            this.router.navigateByUrl(`${this.vehicleType}/${showMonth}/term/totalSummary`, { replaceUrl: true });
        }
    }

    checkInnerWidth(screenWidth: number): void {
        this.mobileView = false;
        if (screenWidth < this.maxMobileWidth) {
            this.mobileView = true;
        }
    }
}
